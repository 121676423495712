import { MouseEvent, ReactNode } from 'react'

import styles from './IconButton.module.css'

export enum IconButtonSize {
  S = 'size_s',
  M = 'size_m',
  ML = 'size_ml',
  L = 'size_l',
}

export enum IconButtonTheme {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  TEXTINPUT = 'textInput',
  BRIGHT = 'bright',
  MAIN_COLOR = 'mainColor',
  AIPARSE = 'aiparse',
}

type IconButtonProps = {
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  onMouseEnter?: (event: MouseEvent<HTMLButtonElement>) => void
  onMouseLeave?: (event: MouseEvent<HTMLButtonElement>) => void
  className?: string
  disabled?: boolean
  theme?: IconButtonTheme
  children?: ReactNode
  size?: IconButtonSize
  icon: ReactNode
  tabIndex?: number
  type?: 'button' | 'submit' | 'reset' | undefined
  active?: boolean
}

export default function IconButton(props: IconButtonProps): ReactNode {
  const {
    theme = IconButtonTheme.PRIMARY,
    icon = null,
    onClick,
    onMouseEnter,
    onMouseLeave,
    className = '',
    disabled = false,
    active = false,
    children = null,
    tabIndex = 0,
    size,
    type = 'button',
  } = props

  return (
    <button
      {...{
        type,
        disabled,
        onClick,
        onMouseEnter,
        onMouseLeave,
        tabIndex,
      }}
      className={`
        ${styles.iconButton}
        ${className}
        ${theme ? styles[theme] : ''}
        ${active ? styles.active : ''}
        ${size ? styles[size] : ''}
      `}
    >
      {icon}
      {children}
    </button>
  )
}
